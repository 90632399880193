import React, { useContext } from 'react'
import {
  TableLink,
  SmallDescription,
  TableContentContainer,
  EntityCountDisplay
} from '../../components/table/tableElements'
import { getAverage, getDurationString, getPtCount, getTableLinkParams } from '../../utils/helper/Helper'
import {
  CONTENT_TYPES,
  FIELD_CATEGORIES,
  FIELD_LABELS,
  FILTER_TYPES,
  PERSEO_DEFAULT,
  PT_STATUS,
  PT_STATUS_NAMES
} from '../../utils/constants/constants'
import { PAGES } from '../../utils/constants/pages'
import { createDate, secondsToDHM } from '../../utils/helper/dateTimeHelper'
import useTranslate from '../../utils/hooks/useTranslate'
import DateDisplay from '../../components/dateDisplay/DateDisplay'
import {
  getActionsCol,
  getCreatedByCol,
  getDateCreatedCol,
  getSpacerCol,
  getVisibilityCol
} from '../../components/table/tableCols/tableCols'
import { FilterContext } from '../../utils/context/FilterContext'
import useRedirect from '../../utils/hooks/useRedirect'

export const getAssessmentTableCols = (t, completeDataSet) => [
  {
    Header: 'freeTextFilter',
    id: 'freeText',
    accessor: (row) => row.assessmentName + row.relatedProcess.processName,
    filterOptions: { type: FILTER_TYPES.textInput },
    canExport: false,
    show: false
  },
  {
    Header: 'Test',
    accessor: 'assessmentName',
    id: 'assessmentName',
    canExport: true,
    filterOptions: { showInFieldSelector: true, category: FIELD_CATEGORIES.test, label: 'testName' },
    Cell: ({ row }) => {
      return (
        <TableContentContainer>
          <span className={row.original.isArchived() ? 'archived' : ''}>{row.original.assessmentName}</span>
          {row.original.assessmentDescription && (
            <SmallDescription>{row.original.assessmentDescription}</SmallDescription>
          )}
        </TableContentContainer>
      )
    }
  },
  {
    Header: 'processes',
    id: 'processName',
    canExport: true,
    filterOptions: { showInFieldSelector: true, category: FIELD_CATEGORIES.process, label: 'processName' },
    show: true,
    accessor: (row) => row.relatedProcess.processName,
    Cell: ({ row }) => {
      const { addLinkFilters } = useContext(FilterContext)
      const process = row.original.relatedProcess
      const tableLinkParams = getTableLinkParams(process, 'processName', 'processes')
      const redirect = useRedirect()

      return (
        <TableLink
          className={tableLinkParams.className}
          onClick={() => {
            addLinkFilters(tableLinkParams.filterParams)
            redirect(PAGES.processes)
          }}>
          {process.processName}
        </TableLink>
      )
    }
  },
  {
    Header: 'numberOfPts',
    id: 'ptCount',
    filterOptions: { showInFieldSelector: true, category: FIELD_CATEGORIES.test, contentType: CONTENT_TYPES.number },
    accessor: (row) => {
      const ptCount = getPtCount([row])
      return ptCount.archived + ptCount.visible
    },
    textAlign: 'right',
    canExport: true,
    Cell: ({ row }) => {
      const { addLinkFilters } = useContext(FilterContext)
      const redirect = useRedirect()
      const ptCount = getPtCount([row.original])

      const assessmentFilter = {
        field: { value: 'assessmentName' },
        value: { value: row.original.assessmentName },
        tableName: 'pts'
      }

      const processFilter = {
        field: { value: 'processName' },
        value: { value: row.original.relatedProcess.processName },
        tableName: 'pts'
      }

      return (
        <div style={{ textAlign: 'right' }}>
          <TableLink
            onClick={() => {
              addLinkFilters([assessmentFilter, processFilter])
              redirect(PAGES.pts)
            }}>
            <EntityCountDisplay visible={ptCount.visible} archived={ptCount.archived} />
          </TableLink>
        </div>
      )
    }
  },
  {
    Header: 'duration',
    id: 'testDuration',
    accessor: (row) => getDurationString(row.relatedConfig.configDuration, t).short,
    filterOptions: { showInFieldSelector: true, category: FIELD_CATEGORIES.test },
    canExport: true,
    Cell: ({ value }) => <TableContentContainer>{value}</TableContentContainer>
  },
  {
    Header: FIELD_LABELS.validFrom,
    id: 'validFrom',
    filterOptions: { showInFieldSelector: true, category: FIELD_CATEGORIES.test, contentType: CONTENT_TYPES.date },
    canExport: true,
    accessor: (row) => createDate(row.validFrom),
    Cell: ({ row }) => {
      const validFrom = row.original.validFrom ? createDate(row.original.validFrom) : null
      return (
        <TableContentContainer>
          {validFrom ? <DateDisplay date={validFrom} format={'dateAndTime'} /> : ''}
        </TableContentContainer>
      )
    }
  },
  {
    Header: FIELD_LABELS.validUntil,
    id: 'validUntil',
    filterOptions: { showInFieldSelector: true, category: FIELD_CATEGORIES.test, contentType: CONTENT_TYPES.date },
    canExport: true,
    accessor: (row) => createDate(row.validUntil),
    Cell: ({ row }) => {
      const validUntil = row.original.validUntil ? createDate(row.original.validUntil) : null
      return (
        <TableContentContainer>
          {validUntil ? <DateDisplay date={validUntil} format={'dateAndTime'} /> : ''}
        </TableContentContainer>
      )
    }
  },
  {
    Header: FIELD_LABELS.invitedDuration,
    canExport: true,
    filterOptions: {
      showInFieldSelector: true,
      category: FIELD_CATEGORIES.test,
      contentType: CONTENT_TYPES.time,
      excludedValues: [0]
    },
    show: false,
    id: 'invitedDuration',
    accessor: (row) => row.invitedDuration || '',
    Cell: ({ row }) => {
      const t = useTranslate()
      const invitedDuration = row.original.invitedDuration
      return (
        <TableContentContainer>
          <span>{invitedDuration ? t('dhmString', ...secondsToDHM(invitedDuration)) : ''}</span>
        </TableContentContainer>
      )
    }
  },
  {
    Header: FIELD_LABELS.startedDuration,
    canExport: true,
    filterOptions: {
      showInFieldSelector: true,
      category: FIELD_CATEGORIES.test,
      contentType: CONTENT_TYPES.time,
      excludedValues: [0]
    },
    show: false,
    id: 'startedDuration',
    accessor: (row) => row.startedDuration || '',
    Cell: ({ row }) => {
      const t = useTranslate()
      const startedDuration = row.original.startedDuration
      return (
        <TableContentContainer>
          <span>{startedDuration ? t('dhmString', ...secondsToDHM(startedDuration)) : ''}</span>
        </TableContentContainer>
      )
    }
  },
  {
    Header: FIELD_LABELS.gradingRule,
    canExport: true,
    filterOptions: {
      showInFieldSelector: true,
      category: FIELD_CATEGORIES.test,
      contentType: CONTENT_TYPES.text
    },
    show: false,
    id: 'gradingRule',
    accessor: (row) => completeDataSet.customNorms.find((cn) => cn.normNr === row.normNr)?.name || PERSEO_DEFAULT,
    Cell: ({ value }) => (
      <TableContentContainer>
        <span>{value}</span>
      </TableContentContainer>
    )
  },
  {
    Header: FIELD_LABELS.startScreen,
    canExport: true,
    filterOptions: {
      showInFieldSelector: true,
      category: FIELD_CATEGORIES.test,
      contentType: CONTENT_TYPES.text
    },
    show: false,
    id: 'startScreen',
    accessor: (row) =>
      completeDataSet.customScreens.find((cs) => cs.screenNr === row.screens.screenNrStart)?.name || PERSEO_DEFAULT,
    Cell: ({ value }) => (
      <TableContentContainer>
        <span>{value}</span>
      </TableContentContainer>
    )
  },
  {
    Header: FIELD_LABELS.endScreen,
    canExport: true,
    filterOptions: {
      showInFieldSelector: true,
      category: FIELD_CATEGORIES.test,
      contentType: CONTENT_TYPES.text
    },
    show: false,
    id: 'endScreen',
    accessor: (row) =>
      completeDataSet.customScreens.find((cs) => cs.screenNr === row.screens.screenNrEnd)?.name || PERSEO_DEFAULT,
    Cell: ({ value }) => (
      <TableContentContainer>
        <span>{value}</span>
      </TableContentContainer>
    )
  },
  getCreatedByCol('assessmentCreatedBy', FIELD_CATEGORIES.test),
  getDateCreatedCol('assessmentCreated', FIELD_CATEGORIES.test),
  getVisibilityCol(FIELD_CATEGORIES.test),
  getSpacerCol(),
  getActionsCol()
]

export const getTestStats = (ptList) => {
  const ptsWithResults = ptList.filter((pt) => pt.hasResult())

  const allResults = ptsWithResults.map((pt) => pt.relatedResult.normResult)
  const allResultsFiltered = allResults.filter((n) => n)

  const allGrades = ptsWithResults.map((pt) => parseFloat(pt.relatedResult.grade))
  const allGradesFiltered = allGrades.filter((n) => n)

  const testStats = [
    {
      id: 'totalParticipants',
      value: ptList.length,
      label: 'totalParticipants'
    },
    {
      id: PT_STATUS.waiting,
      value: ptList.filter((pt) => pt.isWaiting()).length,
      label: PT_STATUS_NAMES[PT_STATUS.waiting]
    },
    {
      id: PT_STATUS.ready,
      value: ptList.filter((pt) => pt.isReady()).length,
      label: PT_STATUS_NAMES[PT_STATUS.ready]
    },
    {
      id: PT_STATUS.started,
      value: ptList.filter((pt) => pt.isStarted()).length,
      label: PT_STATUS_NAMES[PT_STATUS.started]
    },
    {
      id: PT_STATUS.finished,
      value: ptList.filter((pt) => pt.isFinished()).length,
      label: PT_STATUS_NAMES[PT_STATUS.finished]
    },
    {
      id: PT_STATUS.expired,
      value: ptList.filter((pt) => pt.isExpired()).length,
      label: PT_STATUS_NAMES[PT_STATUS.expired]
    },
    {
      id: PT_STATUS.deactivated,
      value: ptList.filter((pt) => pt.isDeactivated()).length,
      label: PT_STATUS_NAMES[PT_STATUS.deactivated]
    },
    {
      id: 'avgPoints',
      value: getAverage(allResultsFiltered),
      label: 'averagePoints'
    },
    {
      id: 'avgGrade',
      value: getAverage(allGradesFiltered),
      label: 'averageGrade'
    }
  ]

  return testStats
}
