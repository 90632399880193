import { useContext, useEffect, useReducer } from 'react'
import NormPreview from './normEditor/NormPreview'
import Select from 'react-select'
import {
  ButtonPrimary,
  ButtonSecondary,
  ButtonTertiary,
  Label,
  selectStyles
} from '../../utils/elements/miscElements'
import { fetchData, getOptionByValue, setStatusBar, updateEntities, updateResults } from '../../utils/helper/Helper'
import useTranslate from '../../utils/hooks/useTranslate'
import { AppContext } from '../../utils/context/AppContext'
import { AddButton } from '../table/tableElements'
import { MODAL_TYPES, STATUS_BAR_TYPES } from '../../utils/constants/constants'
import RenameDialog from './sharedComponents/RenameDialog'
import SaveAsDialog from './sharedComponents/SaveAsDialog'
import NormEditorTable from './normEditor/NormEditorTable'
import useEditorReducer from './sharedComponents/useEditorReducer'
import {
  ButtonContainer,
  Container,
  Footer,
  HelpButton,
  InnerContainer,
  LabelRenameButtonContainer,
  LabelSelectContainer,
  MainButtonContainer,
  RenameButton
} from './sharedComponents/editorElements'
import ReactTooltip from 'react-tooltip'
import { getEditorConfig, getEditorOptionsWithDetails, showLoadingIndicator } from './sharedComponents/sharedUtils'
import ScreenEditor from './screenEditor/ScreenEditor'
import { Screen } from '../../entities/Screen'
import styled from 'styled-components'
import ScreenTypeSelection from './screenEditor/ScreenTypeSelection'

const Editor = ({ type }) => {
  const context = useContext(AppContext)
  const t = useTranslate()

  const {
    initialElements,
    initialKeyValue,
    helpLabel,
    elementName,
    addButtonContent,
    helpContent,
    keyName,
    overwriteHeadline,
    confirmDeleteText,
    deleteModalHeadline,
    endpointSave,
    endpointDelete,
    showLanguageSelect,
    saveSuccessMessage,
    hasBoundaryErrorsFn,
    isNewElement,
    hasEmptyFieldsFn
  } = getEditorConfig(type, context, t)

  const editorReducer = useEditorReducer(keyName)

  const initial = {
    renameFieldVisible: false,
    saveAsDialogVisible: false,
    screenTypeSelectionVisible: false,
    selectedkey: initialKeyValue,
    selectedLanguage: 'de',
    elements: initialElements
  }

  const languageOptions = t([
    { label: 'germanFormal', value: 'de' },
    { label: 'germanInformal', value: 'ded' },
    { label: 'english', value: 'en' }
  ])

  const [data, dispatch] = useReducer(editorReducer, initial)
  const currentElement = data.elements.find((n) => n[keyName] === data.selectedkey)
  const options = getEditorOptionsWithDetails(data.elements, t, keyName)

  const hasEmptyFields = hasEmptyFieldsFn(currentElement)
  const hasBoundaryErrors = hasBoundaryErrorsFn(currentElement)
  const keyValue = currentElement[keyName]

  useEffect(() => {
    const emptyUpperFields = document.querySelectorAll('.upper-limit .has-error')
    if (emptyUpperFields.length) {
      emptyUpperFields[0].focus()
    }
  }, [currentElement?.normTable?.length])

  const saveAs = (newName) => {
    showLoadingIndicator(context)
    dispatch({ type: 'save_as_dialog_visibility_set', value: false })
    saveElement(newName, null)
  }

  const save = () => {
    const numberRelatedTests = getNumberRelatedTests(context.completeDataSet.assessments, type, keyName, keyValue)
    if (numberRelatedTests > 0) {
      openConfirmSaveModal(numberRelatedTests)
    } else {
      showLoadingIndicator(context)
      saveElement(currentElement.name, currentElement[keyName])
    }
  }

  const saveElement = async (name, key) => {
    const payload = getPayload(name, key)
    const responseData = await fetchData(payload, endpointSave, context, 'ruleSaveError')

    try {
      const { resultNrs = [], processResultNrs = [] } = responseData?.response?.resultsToUpdate || {}
      if (responseData.response.status === 1) {
        updateEntities(responseData.response.data, context)

        await updateResults(resultNrs, processResultNrs, context)

        const filteredElements = data.elements.filter((n) => n[keyName] !== key)
        const returnedElement = getReturnedElement(type, responseData)
        dispatch({
          type: 'updated_elements',
          selectedkey: returnedElement[keyName],
          elements: [...filteredElements, returnedElement]
        })
        setStatusBar({
          controller: context.statusBarController,
          type: STATUS_BAR_TYPES.success,
          text: saveSuccessMessage,
          setVisible: true
        })
      }
    } catch (e) {
      console.error(e)
    }
  }

  const getReturnedElement = (type, responseData) => {
    switch (type) {
      case 'norms':
        return responseData.response.data.customNorms[0]
      case 'screens':
        return new Screen(responseData.response.data.customScreens[0])
      default:
        throw new Error('Unknown response object')
    }
  }

  const getPayload = (name, key) => {
    const keySendValue = isNewElement(key) ? null : key

    switch (type) {
      case 'norms': {
        return {
          name: name,
          keyValue: keySendValue,
          parameters: {
            row_count: currentElement.normTable.length,
            grade_naming: 'Note',
            norm_table: currentElement.normTable.map((row) => {
              const upperLimit = row.upper_limit === 100 ? 101 : row.upper_limit
              return {
                lower_limit: row.lower_limit,
                upper_limit: upperLimit,
                grade: row.grade,
                verbalisation: row.verbalisation
              }
            })
          }
        }
      }
      case 'screens': {
        return {
          name: name,
          keyValue: keySendValue,
          type: currentElement.type,
          content: {
            de: `<h1>${currentElement.content.de.headline}</h1> ${currentElement.content.de.body}`,
            en: `<h1>${currentElement.content.en.headline}</h1> ${currentElement.content.en.body}`,
            ded: `<h1>${currentElement.content.ded.headline}</h1> ${currentElement.content.ded.body}`
          }
        }
      }
    }
  }

  const canSave = !hasBoundaryErrors && !hasEmptyFields && !currentElement?.disabled
  const canSaveAs = !hasBoundaryErrors && !hasEmptyFields
  const canDelete = !currentElement?.disabled
  const hasUnsavedNewElement = data.elements.find((n) => isNewElement(n[keyName]))
  const addButtonDisabled = hasUnsavedNewElement || data.renameFieldVisible

  const deleteElement = async () => {
    if (isNewElement(currentElement[keyName])) {
      dispatch({
        type: 'removed_element',
        keyToRemove: currentElement[keyName]
      })
      return
    }

    const payload = { key: currentElement[keyName] }
    const responseData = await fetchData(payload, endpointDelete, context)

    try {
      const { resultNrs = [], processResultNrs = [] } = responseData?.response?.resultsToUpdate || {}
      if (responseData.response.status === 1) {
        updateEntities(responseData.response.data, context)
        await updateResults(resultNrs, processResultNrs, context)

        dispatch({
          type: 'removed_element',
          keyToRemove: currentElement[keyName]
        })

        setStatusBar({
          controller: context.statusBarController,
          type: STATUS_BAR_TYPES.success,
          text: 'fieldsSuccessfullyChanged',
          setVisible: true
        })
      }
    } catch (e) {
      console.error(e)
    }
  }

  const openConfirmSaveModal = (numberRelatedTests) => {
    const text = t('editorDeleteWarning', numberRelatedTests, 'overwrite', type)
    context.setModalProps({
      headline: overwriteHeadline,
      content: text,
      buttonPrimary: (
        <ButtonPrimary
          modalButton
          content="overwrite"
          onClick={() => {
            showLoadingIndicator(context)
            saveElement(currentElement.name, currentElement[keyName])
            context.setModalOpen(false)
          }}
        />
      ),
      icon: 'icon-save modal',
      type: MODAL_TYPES.alert
    })
    context.setModalOpen(true)
  }

  const openConfirmDeleteModal = () => {
    const numberRelatedTests = getNumberRelatedTests(context.completeDataSet.assessments, type, keyName, keyValue)

    const text =
      numberRelatedTests > 0 ? t('editorDeleteWarning', numberRelatedTests, 'delete', type) : confirmDeleteText
    context.setModalProps({
      headline: deleteModalHeadline,
      content: text,
      buttonPrimary: (
        <ButtonPrimary
          modalButton
          warning
          content="delete"
          onClick={() => {
            deleteElement()
            context.setModalOpen(false)
          }}
        />
      ),
      icon: 'icon-delete modal',
      type: MODAL_TYPES.alert
    })
    context.setModalOpen(true)
  }

  const handleAddButtonClick = () => {
    switch (type) {
      case 'screens': {
        dispatch({ type: 'screen_type_selection_visibility_set', value: true })
        break
      }
      default:
        dispatch({ type: 'empty_element_added' })
    }
  }

  const addEmptyScreen = (screenType) => {
    dispatch({ type: 'empty_element_added', options: screenType })
  }

  return (
    <Container>
      <HelpButton content={helpContent} label={helpLabel} />
      <InnerContainer>
        <ButtonContainer showLanguageSelect={showLanguageSelect}>
          <LabelSelectContainer>
            <LabelRenameButtonContainer>
              <Label>{data.renameFieldVisible ? t('title') : t(elementName)}</Label>
              {!data.renameFieldVisible && canDelete && (
                <RenameButton onClick={() => dispatch({ type: 'toggle_rename_field' })}>{t('rename')}…</RenameButton>
              )}
            </LabelRenameButtonContainer>
            {data.renameFieldVisible ? (
              <RenameDialog
                initialName={currentElement.name}
                onOk={(value) => dispatch({ type: 'renamed_element', value: value })}
                onCancel={() => dispatch({ type: 'toggle_rename_field' })}
              />
            ) : (
              <Select
                options={options}
                onChange={(selectedOption) => dispatch({ type: 'key_set', value: selectedOption.value })}
                value={getOptionByValue(options, currentElement[keyName])}
                styles={selectStyles}
                isSearchable={false}
                height="48px"
              />
            )}
          </LabelSelectContainer>
          <div
            className="tooltip-absolute editor-tooltip"
            data-for="addSchemeTipp"
            data-tip=""
            style={{ marginLeft: 'auto', position: 'relative' }}>
            <AddButton
              style={{ position: 'relative' }}
              disabled={addButtonDisabled || data.screenTypeSelectionVisible}
              onClick={handleAddButtonClick}>
              + {t(addButtonContent)}
              {hasUnsavedNewElement && (
                <ReactTooltip
                  id="addSchemeTipp"
                  aria-haspopup="true"
                  effect="solid"
                  place="left"
                  backgroundColor="var(--c-tooltip-bg)"
                  textColor="var(--tooltip-text-color)"
                  getContent={() => <div style={{ width: '120px' }}>{t('saveNewSchemeFirst')}</div>}
                  overridePosition={() => ({ top: -16, left: -160 })}
                />
              )}
            </AddButton>

            {data.screenTypeSelectionVisible && (
              <ScreenTypeSelection
                addEmptyScreen={addEmptyScreen}
                closeDialog={() => dispatch({ type: 'screen_type_selection_visibility_set', value: false })}
              />
            )}
          </div>
          {showLanguageSelect && (
            <LangTabContainer className="lang-tab-container">
              {languageOptions.map((lang) => (
                <LangTab
                  onClick={() =>
                    dispatch({
                      type: 'updated_language',
                      language: lang.value
                    })
                  }
                  isActive={lang.value === data.selectedLanguage}
                  className="lang-tab"
                  key={lang.value}>
                  {lang.label}
                </LangTab>
              ))}
            </LangTabContainer>
          )}
        </ButtonContainer>

        {type === 'norms' && (
          <>
            <NormEditorTable currentNorm={currentElement} dispatch={dispatch} />
            <NormPreview normTable={currentElement.normTable} hasBoundaryErrors={hasBoundaryErrors} />
          </>
        )}

        {type === 'screens' && (
          <ScreenEditor screen={currentElement} dispatch={dispatch} language={data.selectedLanguage} />
        )}

        <Footer>
          {canDelete && (
            <ButtonTertiary
              disabled={data.renameFieldVisible}
              style={{ flexGrow: 0 }}
              content={t('delete') + '…'}
              onClick={openConfirmDeleteModal}
            />
          )}
          <MainButtonContainer>
            <ButtonSecondary
              disabled={!canSaveAs || data.renameFieldVisible}
              onClick={() => dispatch({ type: 'save_as_dialog_visibility_set', value: true })}
              content={t('saveAs') + '…'}
            />
            {!currentElement.disabled && (
              <ButtonPrimary onClick={save} disabled={!canSave || data.renameFieldVisible} content="save" />
            )}
          </MainButtonContainer>
          {data.saveAsDialogVisible && (
            <SaveAsDialog
              name={currentElement.name}
              onCancel={() => dispatch({ type: 'save_as_dialog_visibility_set', value: false })}
              onOk={(newName) => saveAs(newName)}
            />
          )}
        </Footer>
      </InnerContainer>
    </Container>
  )
}

export default Editor

const getNumberRelatedTests = (assessments, type, keyName, keyValue) => {
  switch (type) {
    case 'norms': {
      return assessments.filter((a) => a[keyName] === keyValue).length
    }
    case 'screens': {
      return assessments.filter((a) => a.screens.screenNrStart === keyValue || a.screens.screenNrEnd === keyValue)
        .length
    }
    default:
      return 0
  }
}

const LangTab = styled.button`
  padding: 4px 8px;
  background: ${(props) => (props.isActive ? 'var(--settings-nav-active-tab-bg-color)' : 'transparent')};
  border-radius: 4px;
  font-size: var(--fs-2);
  height: 28px !important;
  color: ${(props) => (props.isActive ? 'var(--text-color-primary)' : 'var(--text-color-secondary)')};
  &:hover {
    background-color: ${(props) =>
      props.isActive ? 'var(--settings-nav-active-tab-bg-color)' : 'var(--settings-nav-tab-hover-color)'};
  }
`

const LangTabContainer = styled.div`
  display: flex;
  gap: var(--space-2);
`
